<template>
  <div class="addAccount">
    <van-nav-bar
      title="添加员工"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-form @submit="onSubmit">
        <van-field
          v-model="mobile"
          name="mobile"
          label="员工账号"
          placeholder="输入手机号"
          clearable
        >
          <template #button>
            <van-button size="small" native-type="button" type="primary" @click.stop="onsearch">查询</van-button>
          </template>
        </van-field>
        <van-field
          v-model="nickname"
          name="nickname"
          label="员工昵称"
          readonly
        />
        <van-field v-show="memberinfo.id" label=" ">
          <template #input>
            <div class="row1">
              <van-image
                lazy-load
                width="60"
                height="60"
                :src="avatar"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <div class="row2">{{nickname}}</div>
            </div>
          </template>
        </van-field>
        <van-field v-show="false"
          v-model="memberid"
          name="memberid"
          label="员工id"
        />
        <van-cell center title="是否启用" value="">
          <template #right-icon>
            <van-switch
              v-model="checked"
              :active-value="1"
              :inactive-value="0"
              active-color="#ee0a24"
              inactive-color="#dcdee0"
              size="24"
            />
          </template>
        </van-cell>
        <div style="margin: 16px;">
          <van-button round block type="danger" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
    <van-popup v-model="show" :close-on-click-overlay="false">
      <van-loading size="24px" vertical color="#ffffff" text-color="#ffffff">加载中...</van-loading>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "addAccount",
  data() {
    return {
      memberid: 0,
      mobile: "",
      nickname: "",
      avatar: "",
      checked: 1,
      shareid: 0,
      merchid: 0,
      memberinfo: {},
      show: false,
      id: 0,
    }
  },
  mounted() {
    this.shareid = this.$route.query.shareid;
    this.merchid = this.$route.query.merchid;
    this.$parent.getmerchname(this.merchid, "添加子账号");
    this.id = this.$route.query.id ? this.$route.query.id : 0;
    this.init();
  },
  methods: {
    init() {
      if (this.id > 0) {
        this.getinfo();
      }
    },
    getinfo() {
      let _that = null;
      _that = this;
      _that.show = true;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/shops/get_one",
          _that.$qs.stringify({
            id: _that.id
          })
        )
        .then(res => {
          _that.show = false;
          console.log(res);
          if (res.data.code == 100000) {
            _that.memberinfo = res.data.data;
            _that.memberinfo.id = _that.memberinfo.memberid;
            _that.memberid = _that.memberinfo.memberid;
            _that.mobile = _that.memberinfo.mobile;
            _that.nickname = _that.memberinfo.nickname && _that.memberinfo.nickname.length > 2 ? _that.memberinfo.nickname : "未设置昵称";
            _that.avatar = _that.memberinfo.avatar && _that.memberinfo.avatar.length > 10 ? _that.memberinfo.avatar : "https://img.midbest.cn/uploads/image/order/kongjia/2020/10/05/b53233d98f7e4fe5965e8fc70a024b05.png";
            _that.checked = parseInt(_that.memberinfo.status);
          }
        })
        .catch(err => {
          _that.show = false;
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
    // 查询用户
    onsearch() {
      console.log(this.mobile,'mobile');
      let _that = null;
      _that = this;
      _that.show = true;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/shops/search_mobile",
          _that.$qs.stringify({
            mobile: _that.mobile
          })
        )
        .then(res => {
          console.log(res);
          _that.show = false;
          if (res.data.code == 100000) {
            _that.memberinfo = res.data.data;
            _that.memberid = _that.memberinfo.id;
            _that.nickname = _that.memberinfo.nickname && _that.memberinfo.nickname.length > 0 ? _that.memberinfo.nickname : "未设置昵称";
            _that.avatar = _that.memberinfo.avatar && _that.memberinfo.avatar.length > 10 ? _that.memberinfo.avatar : "https://img.midbest.cn/uploads/image/order/kongjia/2020/10/05/b53233d98f7e4fe5965e8fc70a024b05.png";
          }else {
            _that.$toast(res.data.msg ? res.data.msg : '暂未查询到用户')
          }
        })
        .catch(err => {
          _that.show = false;
          console.log(err);
        })
    },
    // 提交数据
    onSubmit() {
      console.log('onSubmit');
      let _that = null;
      _that = this;
      _that.show = true;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/shops/add_account",
          _that.$qs.stringify({
            memberid: _that.memberid,
            status: _that.checked
          })
        )
        .then(res => {
          console.log(res);
          _that.show = false;
          if (res.data.code == 100000) {
            _that.$toast(res.data.msg ? res.data.msg : "提交成功");
            setTimeout(() => {
              _that.onClickLeft();
            }, 1500);
          }else {
            _that.$toast(res.data.msg ? res.data.msg : "提交失败");
          }
        })
        .catch(err => {
          console.log(err);
          _that.show = false;
        })
    },
  },
}
</script>
<style lang="less">
.addAccount {
  padding: 0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
  z-index: 2;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .main {
    overflow: hidden;
    width: 100%;
    position: relative;
    .van-cell__value {
      flex: 1;
    }
  }

  .van-popup {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 10px;
    .van-loading {
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      color: #fff;
    }
  }
  
}
</style>